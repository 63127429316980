.ask {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.copy h2 {
  font-size: 24px;
  margin: 20px 0;
  line-height: 1.4;
}

.copy h3 {
  font-size: 18px;
}

.copy h4 {
  font-size: 18px;
  margin: 0 0 10px 0;
  color: var(--color-gray-lightest);
}

.copy p {
  font-size: 14px;
}

.copyContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.copy {
  max-width: 420px;
}

.calloutVert {
  display: flex;
  flex-direction: column;
}

.calloutVert img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
  border: solid 2px var(--color-gray-darkest);
}

.calloutVert h4 {
  margin-bottom: 10px;
}

.ratio {
  width: 100%;
  padding-top: 100%;
  position: relative;
  margin-bottom: 20px;
}

.visual {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid #1d1d1d;
  transform: translate3d(0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .ask {
    flex-direction: row;
  }

  .ask > * {
    flex: 1 1 0;
  }

  .copyContainer {
    align-items: center;
    justify-content: center;
  }

  .copy h2 {
    font-size: 32px;
    margin: 20px 0 40px 0;
  }

  .copy h3 {
    font-size: 24px;
    margin: 0;
  }

  .copy h4 {
    font-size: 24px;
    margin: 0 0 10px 0;
    color: var(--color-gray-lightest);
  }

  .copy p {
    font-size: 16px;
  }
}