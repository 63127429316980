.market {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.market h2 {
  font-size: 24px;
  margin: 20px 0;
  line-height: 1.4;
}

.market h3 {
  font-size: 18px;
}

.market p {
  font-size: 14px;
}

.threeUp {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.threeUp > * {
  flex: 1 1 0;
  margin: 20px 0;
}

.threeUp .riv-placeholder {
  height: 240px;
}

.threeUp h3 {
  text-align: left;
  color: var(--color-white);
  font-size: 18px;
  font-weight: 300;
  margin: 25px 0 10px 0;
}

.divider {
  display: none;
}

.ratio {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.featureAnimation {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid var(--color-gray-darkest);
  background-color: var(--color-gray-darkest);
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .market {
    align-items: center;
    justify-content: center;
  }

  .market h2 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 25px;
  }
  
  .market h3 {
    font-size: 24px;
    text-align: center;
    line-height: 1.6;
  }
  
  .market > p {
    max-width: 600px;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 40px;
    font-size: 20px;
  }

  .threeUp {
    flex-direction: row;
  }

  .threeUp > * {
    margin: 0 20px;
  }

  .threeUp h3 {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 20px;
    color: var(--color-white);
    font-size: 24px;
    font-weight: 500;
  }

  .threeUp p {
    font-size: 16px;
  }

  .divider {
    display: block;
    height: 4px;
    margin: 0 5px 25px 5px;
    border-radius: 2px;
    background-color: var(--color-gray-darker);
  }
}