.stateMachine {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.stateMachine h2 {
  font-size: 24px;
  margin: 20px 0;
}

.stateMachine h3 {
  font-size: 18px;
  line-height: 1.4;
}

.stateMachine h4 {

}

.stateMachine p {
  font-size: 14px;
  margin-bottom: 20px;
}

.stateMachine > .riv-placeholder {
  width: 100%;
  flex-grow: 1;
}

.playerWrapper {
  width: 100%;
  position: relative;
  padding-top: 28.5%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  transform: translate3d(0, 0, 0);
  border: solid 2px var(--color-gray-darkest);
  background-color: var(--color-gray-darker);
  /* box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4); */
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.01);
}

.examples {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.examples > * {
  width: 100%;
  max-width: none;
  /* aspect-ratio: 1; */
}

@media (min-width: 768px) {
  .stateMachine {
    align-items: center;
    justify-content: center;
  }

  .stateMachine h3 {
    max-width: 720px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .stateMachine h4 {
    max-width: 600px;
    text-align: center;
    line-height: 1.6;
  }

  .stateMachine > p {
    max-width: 600px;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 40px;
    font-size: 20px;
  }

  .examples {
    flex-direction: row;
    justify-content: center;
  }

  .examples > * {
    flex: 1 1 0;
    max-width: 360px;
  }

  .examples > *:nth-child(2) {
    margin: 0 10px;
  }
}