.strategy {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.strategy h2 {
  font-size: 24px;
  margin: 20px 0;
  line-height: 1.4;
}

.strategy h3 {
  font-size: 18px;
  line-height: 1.6;
}

.strategy > h4 {
  font-size: 14px;
  color: #8c8c8c;
  margin: 0 0 20px 0;
  line-height: 1.6;
}

.strategy p {
  font-size: 14px;
}

.divider { 
  height: 2px;
  border-radius: 1px;
  margin: 0 0 20px 0;
  background-color: var(--color-gray-darker);
}

.threeUp {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.threeUp > * {
  flex: 1 1 0;
}

.threeUp .rivPlaceholder {
  margin: 0 0 20px 0;
  height: 240px;
}

.threeUp h3 {
  text-align: left;
  margin: 10px 0;
  color: var(--color-gray-light);
}

.threeUp h4 {
  line-height: 1.4;
}

.ratio {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.featureAnimation {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid var(--color-gray-darkest);
  background-color: var(--color-gray-darkest);
  transform: translate3d(0, 0, 0);
}

.calloutVert {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  /* margin-left: 10px;
  margin-right: 10px; */
}

.calloutVert img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
  border: solid 2px var(--color-gray-darkest);
}

.calloutVert h4 {
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .strategy {
    align-items: center;
    justify-content: center;
  }

  .strategy h2 {
    margin-bottom: 25px;
  }
  
  .strategy h3 {
    line-height: 1.6;
  }
  
  .strategy > h4 {
    max-width: 600px;
    text-align: center;
    line-height: 1.6;
    font-size: 20px;
    margin: 0 0 40px 0;
  }

  .strategy p {
    font-size: 16px;
  }

  .divider { 
    height: 4px;
    margin: 0 5px 25px 5px;
  }

  .threeUp {
    flex-direction: row;
  }

  .threeUp > * {
    margin: 0 20px;
  }

  .threeUp h3 {
    text-align: center;
    margin: 25px 0 20px 0;
  }

  .calloutVert {
    margin: 0 20px 20px 20px;
  }

  .calloutVert h4 {
    margin-bottom: 10px;
  }
}