.roadmap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.roadmap h2 {
  font-size: 24px;
  margin: 20px 0;
  line-height: 1.4;
}

.roadmap h3 {
  font-size: 18px;
}

.roadmap > h4 {
  font-size: 14px;
  color: #8c8c8c;
  margin: 0 0 20px 0;
  line-height: 1.6;
}

.roadmap p {
  font-size: 14px;
}

.features {
  display: flex;
  flex-direction: column;
}

.feature > * {
  flex: 1 1 0;
}

.ratio {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.featureAnimation {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid var(--color-gray-darkest);
  background-color: var(--color-gray-darkest);
  transform: translate3d(0, 0, 0);
}

.calloutVert {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.calloutVert img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
  border: solid 2px var(--color-gray-darkest);
}

.calloutVert h4 {
  margin-bottom: 5px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .roadmap {
    align-items: center;
    justify-content: center;
  }

  .roadmap h2 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 25px;
  }

  .roadmap h3 {
    font-size: 24px;
    text-align: center;
    line-height: 1.6;
  }
  
  .roadmap > h4 {
    max-width: 600px;
    text-align: center;
    line-height: 1.6;
    font-size: 20px;
    margin: 0 0 40px 0;
  }

  .roadmap p {
    font-size: 16px;
  }

  .features {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .calloutVert h4 {
    margin-bottom: 10px;
  }
}