.applications {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.applications h2 {
  font-size: 24px;
  margin: 20px 0;
  line-height: 1.4;
}

.applications h3 {
  font-size: 18px;
}

.applications h4 {
  margin: 5px 0;
}

.applications p {
  font-size: 14px;
}

.playerWrapper {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  transform: translate3d(0, 0, 0);
  border: solid 2px var(--color-gray-darkest);
  background-color: var(--color-gray-darker);
  /* box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4); */
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.02);
}

.copyContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-evenly;
  max-width: 1440px;
}

.copyContainer > * {
  flex: 1 1 0;
  /* margin-bottom: 40px; */
}

.rivPlaceholder {
  flex-grow: 1;
}

.expandable {
  margin-right: 20px;
}

.calloutVert {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.calloutVert img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
  border: solid 2px var(--color-gray-darkest);
}

.calloutVert h4 {
  margin: 5px 0;
}

@media (min-width: 768px) {
  .applications {
    align-items: center;
    justify-content: center;
  }

  .applications h2 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 25px;
  }

  .applications h3 {
    font-size: 24px;
    text-align: center;
    line-height: 1.6;
  }

  .applications h4 {
    /* margin: 0 0 10px 0; */
  }

  .applications p {
    font-size: 16px;
  }

  .copyContainer {
    flex-direction: row;
  }

  .calloutVert {
    margin-right: 20px;
  }

  .calloutVert h4 {
    margin: 0 0 10px 0;
  }
}