.RiveSwitch {
  width: 48px;
  height: 24px;
  cursor: pointer;
}

.RiveSliderContainer {
  height: 36px;
  margin: 0 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Rail {
  width: 100%;
  height: 12px;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #282828;
  border: 2px solid #242424;
}

.Rail > div {
  width: 100%;
  height: 100%;
  top: 0; left: 0;
  position: absolute;
  background-color: #303030;
  transform-origin: top left;
}

.Thumb {
  width: 46px;
  height: 46px;
  position: absolute;
  top: -5px;
  left: -23px;
  cursor: grab;
}

.RiveLoader {
  width: 24px;
  height: 24px;
  overflow: hidden;
}