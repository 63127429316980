.cover {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
}

.cover > * {
  flex: 1 1 0;
}

.animationContainer {
  height: calc(100vh - 80px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  height: 20px;
}

.mnemonic {
  width: 100%;
  aspect-ratio: 3;
}

.linkContainer {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.copyContainer h1 {
  color: var(--color-white);
  font-size: 20px;
  font-weight: 300;
  margin: 20px 0 0 0;
}

.copyContainer h2 {
  color: var(--color-gray-light);
  font-size: 20px;
  font-weight: 300;
  margin: 10px 0 0 0;
}

.copyContainer a,
.copyContainer button {
  color: var(--color-gray-light);
  text-decoration: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 300;
  font-family: 'Roboto';
  margin: 0 16px 0 6px;
  cursor: pointer;
  padding: 0;
  border: none;
  display: flex;
}

.copyContainer a:hover,
.copyContainer button:hover {
  color: var(--color-white);
}

.spacer {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .cover {
    flex-direction: row;
  }

  .animationContainer {
    height: auto;
  }

  .copyContainer {
    align-items: flex-start;
    justify-content: center;
  }

  .linkContainer,
  .copyContainer > h1 {
    margin-left: 40px;
  }

  .copyContainer h1 {
    font-size: 32px;
  }

  .mnemonic {
    width: 300px;
  }

  .linkContainer {
    flex-direction: row;
  }
}