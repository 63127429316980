.container {
  width: 100%;
  padding-top: 100%;
  position: relative;
  margin: 15px 0;
}

.visual {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;
  border: 2px solid #1d1d1d;
  transform: translate3d(0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttonCluster {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  height: 36px;
  border-radius: 18px;
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid #1d1d1d;
  background-color: #1d1d1d;
}

.levelButton {
  background-color: #242424;
  color: #444444;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  outline: none;
  border: none;
  margin: 0;
}

.levelButtonActive {
  font-weight: 500;
  color: #f1f1f1;
  background-color: #303030;
}

.AnswerCluster {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  height: 36px;
  border-radius: 18px;
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid #1d1d1d;
  background-color: #1d1d1d;
}

.AnswerButton {
  background-color: #242424;
  color: #f1f1f1;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  outline: none;
  border: none;
  margin: 0;
}

.square {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.neo {
  width: 100%;
  height: 100%;
  top: 0; left: 0;
  position: absolute;
}

.flameButton {
  width: 12%;
  height: 16%;
  position: absolute;
  transform: translate(-50%, -60%);
}

.labelAbove {
  top: -50px;
}

.labelBelow {
  bottom: -30px;
}

.flameButton h4 {
  width: 100%;
  position: absolute;
  text-align: center;
  transition: opacity 0.25s ease;
}

.flameButton button {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.flameButton button:focus {
  outline:0;
}

.graph {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  position: absolute;
  top: 0; left: 0;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 0 30px 30px;
  border: 2px solid var(--color-gray-darkest);
  background-color: var(--color-gray-darker);
  transform: translate3d(0, 0, 0);
}

.xAxis {
  width: calc(100% - 40px);
  height: 100%;
  top: 0; left:0;
  margin-left: 40px;
  display: flex;
  position: absolute;
  align-items: flex-end;
  justify-content: space-between;
  pointer-events: none;
}

.yAxis {
  width: 100%;
  height: calc(100% - 40px);
  top: 0; left:0;
  margin-bottom: 40px;
  display: flex;
  position: absolute;
  flex-direction: column-reverse;
  justify-content: space-between;
  pointer-events: none;
}

.xAxis p,
.yAxis p {
  font-size: 12px !important;
}

.graphSegment {
  height: 2px;
  width: calc(100% - 30px);
  opacity: 0.5;
  margin-left: 30px;
  background-color: var(--color-gray-darkest);
}

.yAxis > *:last-child .graphSegment {
  display: none;
}