.team {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.team > h3 {
  font-size: 18px;
  /* margin-bottom: 20px; */
}

.header > h4 {
  font-size: 14px;
  text-align: left;
  line-height: 1.6;
  /* margin: 20px 0; */
  color: var(--color-gray-lightest)
}

.header {
  display: flex;
  flex-direction: column-reverse;
}

.departmentHeader,
.departments {
  display: flex;
}

.engineers, .designers {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

.teamMember {
  /* width: calc(50% - 40px); */
  /* width: 100%; */
  /* display: flex; */
  /* flex-direction: column; */
}

.teamMember > * {
  /* flex: 0 0 auto; */
  /* width: 50%; */
}

.image {
  flex: 0 0 auto;
  width: 100%;
  /* height: 40px; */
  aspect-ratio: 1;
  /* height: 100px; */
  /* width: calc(35% - 20px); */
  /* margin: 0 20px 20px 0; */
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px;
  border: 2px solid var(--color-gray-darkest);
}

.founders .teamMember:first-child {
  margin-right: 2.5px;
}

.founders .teamMember:last-child {
  margin-left: 2.5px;
}

.founders {
  display: flex;
  margin-top: 20px;
  /* flex-direction: column; */
}

/* .founders img {
  width: calc(40% - 20px);
  border-radius: 5px;
}

.founders h2 {
  font-size: 28px;
  margin: 20px 0 20px 0;
} */

.teamMember h2 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.teamMember h4 {
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 15px 0;
  color: var(--color-gray-lightest)
}

.teamMember p {
  font-size: 14px;
}

.departmentHeader {
  width: 100%;
  display: flex;
}

.departmentHeader > h6 {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
  margin: 20px 0 20px 0;
  color: var(--color-gray-light);
  border-bottom: 2px solid var(--color-gray-light);
}

.departmentHeader > h6:first-child {
  margin-right: 2.5px;
}

.departmentHeader > h6:last-child {
  margin-left: 2.5px;
}

.departments + h6 {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-gray-light);
  border-top: 2px solid var(--color-gray-light);
}

.investors {
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.investors > * {
  margin: 20px 0;
}

@media (min-width: 768px) {
  .team {
    align-items: center;
    justify-content: center;
  }

  .header {
    align-items: center;
    flex-direction: column;
  }

  .team > h3 {
    font-size: 24px;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .header > h4 {
    font-size: 20px;
    max-width: 1100px;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 40px;
  }

  .founders .teamMember,
  .founders .teamMember:first-child,
  .founders .teamMember:last-child {
    width: 100%;
    margin: 20px;
  }

  .departments {
    width: 100%;
    margin-bottom: 40px;
  }

  .teamMember {
    width: calc(50% - 40px);
    flex-direction: row;
    display: flex;
    margin: 10px 20px;
  }
  
  .teamMember img {
    flex: 0 0 auto;
    width: calc(35% - 20px);
    margin: 0 20px 20px 0;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5px;
    border: 2px solid var(--color-gray-darkest);
  }

  .teamMember h2 {
    font-size: 20px;
    margin: 5px 0 10px 0;
  }
  
  .teamMember h4 {
    font-size: 16px;
    margin: 0 0 10px 0;
  }
  
  .teamMember p {
    font-size: 16px;
  }

  .founders {
    flex-direction: row;
  }

  .founders img {
    width: calc(40% - 20px);
    border-radius: 5px;
  }

  .departmentHeader > h6 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 0 20px;
    padding-bottom: 10px;
  }

  .departmentHeader > h6:first-child {
    margin-right: 0;
  }
  
  .departmentHeader > h6:last-child {
    margin-left: 20px;
  }

  .departments + h6 {
    font-size: 20px;
    font-weight: 500;
    margin-left: 20px;
    width: calc(100% - 20px);
  }

  .investors {
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
  }

  .investors > * {
    margin: 0 40px;
  }
}
