:root {
  --color-gray-lightest: #8c8c8c;
  --color-gray-light: #666666;
  --color-gray: #303030;
  --color-gray-darker: #242424;
  --color-gray-darkest: #1d1d1d;
  --color-white: #f1f1f1;
}

* {
  box-sizing: border-box;
}

h1 {
  margin: 40px 0 10px 0;
  font-size: 36px;
  font-weight: 500;
}
  h1 span {
    color: var(--color-gray-light);
  }
h2 {
  margin: 20px 0 40px 0;
  font-size: 32px;
  font-weight: 500;
}
h3 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  color: var(--color-gray-light);
}
h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 300;
}
p {
  margin: 0;
  line-height: 1.6;
  color: var(--color-gray-lightest);
}

a{
  color: var(--color-white);
}

.slide {
  min-height: 100vh;
  margin: auto;
  padding: 20px;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  background-color: var(--color-gray);
}

 @media (min-width: 768px) {
  .slide {
    padding: 40px;
  }
 }

.aspect-ratio-16x9 {
  height: 0;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  /* overflow: hidden; */
  background-color: red;
}

.slide-content {
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
}

.divider {
  height: 2px;
  margin: 0 20px;
  border-radius: 1px;
  background-color: var(--color-gray-darker);
}

@media (min-width: 768px) {
  .divider {
    height: 4px;
    margin: 0 80px;
    border-radius: 2px;
    background-color: var(--color-gray-darker);
  }
 }

.riv-placeholder {
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: var(--color-gray-lightest);
  background-color: var(--color-gray-darkest);
}

.riv-container {
  width: 100%;
  height: calc(100vh - 80px);
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: relative;
  margin: auto;
  /* background-color: red; */
}

.icon-container {
  width: 60px;
  height: 60px;
  flex: 0 0 auto;
  margin-top: 5px;
  margin-right: 20px;
  border-radius: 5px;
  overflow: hidden;
}

/* Cover, Problem and Solution */

.cover, .problem, .solution, .ask {
  display: flex;
  justify-content: space-evenly;
}

.cover > *,
.problem > *,
.solution > *,
.ask > * {
  flex: 1 1 0;
}

.problem .copy-container,
.solution .copy-container,
.ask .copy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.problem .copy, 
.solution .copy,
.ask .copy {
  max-width: 420px;
}

.callout {
  display: flex;
  /* align-items: center; */
  margin-left: 0px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.callout h4 {
  line-height: 1.6;
  margin-bottom: 5px;
}

.callout-vert {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.callout-vert img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
  border: solid 2px var(--color-gray-darkest);
}

/* .callout-vert h3, */
.callout-vert h4 {
  margin-bottom: 10px;
}

/* Applications */

.applications {
  display: flex;
  flex-direction: column;
}

.player-wrapper,
.player-wrapper-state-machine {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  border: solid 2px var(--color-gray-darkest);
  background-color: var(--color-gray-darker);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.01);
}

.applications .copy-container {
  display: flex;
  margin-top: 20px;
  justify-content: space-evenly;
}

.applications .copy-container > * {
  /* flex: 1 1 0; */
  /* margin-bottom: 40px; */
}

.applications > .riv-placeholder {
  flex-grow: 1;
}

.applications .expandable {
  /* margin-right: 20px; */
}

/* Team */

.team {
  display: flex;
  flex-direction: column;
}

.team > h3 {
  margin-bottom: 20px;
}

.team > div {
  display: flex;
}

.engineers, .designers {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

.team-member {
  width: calc(50% - 40px);
  display: flex;
  margin: 20px;
}

.team-member img {
  flex: 0 0 auto;
  width: calc(35% - 20px);
  margin: 0 20px 20px 0;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px;
  border: 2px solid var(--color-gray-darkest);
}

.founders img {
  width: calc(40% - 20px);
  border-radius: 5px;
}

.team-member h2 {
  font-size: 20px;
  margin: 5px 0 10px 0;
}

.founders h2 {
  font-size: 28px;
  margin: 20px 0 20px 0;
}

.team-member h4 {
  font-size: 16px;
  margin: 0 0 10px 0;
}

.department-header {
  width: 100%;
  display: flex;
}

.department-header > h6 {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 0 20px;
  padding-bottom: 10px;
  color: var(--color-gray-light);
  border-bottom: 2px solid var(--color-gray-light);
}

/* Traction */

.trajectory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.trajectory h2
{
  max-width:900px;
  text-align:center;
}

.roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.roadmap h4.para-head,
.strategy h4.para-head,
.trajectory h4.para-head {
  max-width: 600px;
  text-align: center;
  line-height: 1.6;
  margin-bottom: 40px;
}

.team h4.para-head {
  max-width: 1100px;
  text-align: center;
  line-height: 1.6;
  margin-bottom: 40px;
}

.strategy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.strategy h2,
.roadmap h2,
.strategy h2,
.trajectory h2 {
  margin-bottom: 25px;
}

.strategy h3 {
  line-height: 1.6;
}

.strategy .divider { 
  margin: 0 5px 25px 5px;
  background-color: var(--color-gray-darker);
}

.features {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.feature-animation {
  width: 100%;
  height: 240px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid var(--color-gray-darkest);
  background-color: var(--color-gray-darkest);
}

.three-up {
  margin: 20px;
  display: flex;
  justify-content: space-evenly;
}

.three-up > * {
  flex: 1 1 0;
  margin: 0 20px;
}

.three-up .riv-placeholder {
  margin: 0 0 20px 0;
  height: 240px;
}

.three-up h3 {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
  color: var(--color-white);
}

.strategy .callout-vert {
  margin-left: 10px;
  margin-right: 10px;
}

.expandable {
  /* cursor: pointer; */
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  margin: auto;
  width: 50%;
  max-width: 720px;
  padding: 40px;
  border-radius: 15px;
  background-color: var(--color-gray-darker);
  box-shadow: 0px 12px 80px rgba(0, 0, 0, 0.8);
  border: 2px solid var(--color-gray-darkest);
}

.modal h2 {
  margin: 0 0 30px 0;
}

.modal-transition-enter {
  background-color: rgba(0, 0, 0, 0);
}
.modal-transition-enter-active {
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 250ms;
}
.modal-transition-exit {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-transition-exit-active {
  background-color: rgba(0, 0, 0, 0);
  transition: all 250ms;
}

.modal-transition-enter .modal {
  opacity: 0;
  transform: translateY(50px);
}
.modal-transition-enter-active .modal {
  opacity: 1;
  transform: translateY(0px);
  transition: all 250ms;
}
.modal-transition-exit .modal {
  opacity: 1;
  transform: translateY(0px);
}
.modal-transition-exit-active .modal {
  opacity: 0;
  transform: translateY(50px);
  transition: all 250ms;
}