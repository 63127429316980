.runtimes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

.runtimes h2 {
  font-size: 24px;
  margin: 20px 0;
  line-height: 1.4;
}

.runtimes h3 {
  font-size: 18px;
}

.runtimes h4 {
  font-size: 18px;
  margin: 5px 0;
}

.runtimes p {
  font-size: 14px;
}

.ecosystemContainer {
  max-width: 540px;
  margin: 20px 20px 40px 20px;
  position: relative;
}

.runtimeDevicesImg {
  width: 100%;
  object-fit: contain;
}

.ecosystemAnimation {
  position: absolute;
  top: -2px; left: 0;
  width: 100%;
  height: 100%;
}

.platforms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 20px 0;
  max-width: 720px;
}

.runtimeLogo {
  height: 60px;
  object-fit: contain;
  margin: 20px 30px;
}

.runtimeLogo img {
  object-fit: contain;
}

.runtimeTile {
  padding: 0 12px;
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.runtimeTile p {
  font-size: 11px;
}

.runtimeTile a {
  text-decoration: none;
}

.runtimeTile:hover {
  background-color: #1d1d1d;
  color: #f1f1f1;
}

.runtimeLogo {
  height: 35px;
  display: block;
  margin: 10px auto;
}

.runtimeBenefits {
  display: flex;
  flex-direction: column;
}

.benefit {
  flex: 1 1 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.ratio {
  padding-top: 0;
  position: relative;
}

.benefitAnimation {
  max-width: 40%;
}

@media screen and (min-width: 768px) {
  .runtimes {
    align-items: center;
    justify-content: center;
  }

  .header {
    padding-top: 0;
    flex-direction: column;
  }

  .platforms {
    justify-content: center;
    max-width: 600px;
  }

  .runtimes h2 {
    font-size: 32px;
    text-align: center;
  }

  .runtimes h3 {
    font-size: 24px;
    margin: 20px 0;
    text-align: center;
  }

  .runtimes h4 {
    text-align: center;
    margin: 0 0 20px 0;
  }

  .runtimes p {
    font-size: 16px;
    text-align: center;
  }
 
  .runtimeLogo {
    height: 50px;
    flex: 1 1 auto;
  }

  .runtimeTile {
    padding: 0 20px;
  }

  .runtimeTile p {
    font-size: 16px;
  }

  .runtimeBenefits {
    max-width: 1024px;
    flex-direction: row;
  }
  
  .benefit {
    margin: 0 20px;
    flex-direction: column;
  }

  .ratio {
    width: 100%;
    padding-top: 100%;
  }
  
  .benefitAnimation {
    width: 100%;
    height: 100%;
    top:0; left: 0;
    position: absolute;
    max-width: none;
  }
}