.analytics {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.analytics h2 {
  font-size: 24px;
  margin: 20px 0;
  line-height: 1.4;
}

.analytics h3 {
  font-size: 18px;
}

.analytics p {
  font-size: 14px;
}

.threeUp {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.threeUp > * {
  flex: 1 1 0;
  margin: 40px 0 0 0;
}

.threeUp .riv-placeholder {  
  height: 240px;
}

.threeUp h3 {
  text-align: left;
  color: var(--color-white);
  font-size: 18px;
  font-weight: 300;
  margin: 15px 0 0 30px;
}

@media (min-width: 768px) {
  .analytics {
    align-items: center;
    justify-content: center;
  }

  .analytics h2 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 25px;
  }
  
  .analytics h3 {
    font-size: 24px;
    text-align: center;
  }
  
  .analytics > p {
    max-width: 600px;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 40px;
    font-size: 20px;
  }

  .threeUp {
    flex-direction: row;
  }

  .threeUp > * {
    margin: 0 20px;
  }

  .featureAnimation {
    height: 240px;
  }

  .threeUp h3 {
    text-align: center;
    margin: 25px 0 20px 30px;
    color: var(--color-white);
    font-size: 20px;
    font-weight: 300;
  }

  .threeUp p {
    font-size: 16px;
  }
}