.placement {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.placement h2 {
  font-size: 24px;
  margin: 20px 0;
  line-height: 1.4;
}

.placement h3 {
  font-size: 18px;
}

.diagrams {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.diagrams > img {
  flex: 1 1 0;
  width: 100%;
  object-fit: contain;
}

.diagrams > img:first-child {
  margin-bottom: 40px;
}


@media (min-width: 768px) {
  .placement {
    align-items: center;
    justify-content: center;
  }

  .placement h2 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 25px;
  }
  
  .placement h3 {
    font-size: 24px;
    text-align: center;
  }

  .diagrams {
    flex-direction: row;
  }

  .diagrams > img {
    width: 50%;
  }

  .diagrams > img:first-child {
    margin-bottom: 0;
  }
}