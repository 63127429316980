.Overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.Close {
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  margin: 20px;
  position: fixed;
  cursor: pointer;
  background-color: transparent;
}

.Close img {
  width: 100%;
  height: 100%;
}

.EpisodePlayer {
  width: 100%;
  margin: 20px;
  display: flex;
  max-width: 1280px;
  flex-direction: column;
}

.PlayerWrapper {
  position: relative;
  padding-top: 56.25%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  border: solid 2px #1d1d1d;
  background-color: #242424;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.8);
}

.ReactPlayer {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.01);
}

@media screen and (min-width: 800px) {
  .EpisodePlayer {
    margin: 40px;
  }
}
