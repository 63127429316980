.close {
  min-height: 100vh;
  margin: auto;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--color-white);
  background-color: var(--color-gray-darkest);
}

.closeContainer {
  width: 100%;
  max-width: 1440px;
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: space-between;
}

.closeContainer > * {
  width: 100%;
  height: 100%;
  flex: 0 0 auto;
}

.logo {
  display: none;
}

.closeContainer h1 {
  font-size: 32px;
  margin: 0 0 40px 0;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--color-gray-darker);
}

.closeContainer h3 {
  margin-bottom: 10px;
}

.linksContainer {
  display: flex;
  flex-direction: column;
}

.linksContainer > * {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.captionedLink {
  margin-bottom: 15px;
}

.captionedLink a {
  text-decoration: none;
}

.captionedLink a:hover {
  text-decoration: underline;
}

.captionedLink p {
  margin-top: 2px;
}

@media (min-width: 768px) {
  .close {
    padding: 40px;
    align-items: center;
    justify-content: center;
  }

  .closeContainer {
    flex-direction: row;
    padding: 40px;
  }

  .logo {
    display: block;
  }

  .closeContainer > * {
    width: 50%;
    height: 100%;
    flex: 0 0 auto;
  }

  .closeContainer h1 {
    font-size: 36px;
  }

  .closeContainer h3 {
    margin-bottom: 20px;
  }

  .linksContainer {
    flex-direction: row;
  }

  .linksContainer > * {
    width: 50%;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .captionedLink {
    margin-bottom: 20px;
  }
}