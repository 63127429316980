.problem {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: space-evenly;
}

.problem > * {
  flex: 1 1 0;
}

.rivContainer {
  /* height: calc(100vh - 80px); */
  width: 100%;
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
}

.ratio {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.animation {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
}

.copyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.copy {
  max-width: 420px;
}

.copy h2 {
  font-size: 24px;
  margin: 20px 0;
  line-height: 1.4;
}

.copy h3 {
  font-size: 18px;
}

.copy p {
  font-size: 14px;
}

.iconContainer {
  display: none;
}

.callout {
  display: flex;
  margin: 0 0 20px 0
}

@media (min-width: 768px) {
  .problem {
    flex-direction: row;
  }

  .copyContainer {
    justify-content: center;
  }

  .copy h2 {
    font-size: 32px;
  }

  .copy h3 {
    font-size: 24px;
    margin: 20px 0 40px 0;
  }

  .copy p {
    font-size: 16px;
  }

  .callout {
    margin: 0 20px 20px 0
  }

  .iconContainer {
    display: block;
    width: 60px;
    height: 60px;
    flex: 0 0 auto;
    margin-top: 5px;
    margin-right: 20px;
    border-radius: 5px;
    overflow: hidden;
  }
}